<template>
  <svg
    width="385"
    height="385"
    viewBox="0 0 385 385"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-illustration"
  >
    <path
      d="M151.383 110.777C141.835 71.4046 156.875 37.2166 180.335 28.3873C192.501 23.8186 206.771 26.0259 217.141 32.5453C237.52 45.3273 246.349 76.1786 235.415 107.749C238.495 124.74 241.524 141.731 244.604 158.723C243.269 178.897 247.992 192.141 252.663 200.559C260.774 215.138 270.887 219.347 273.505 234.645C274.942 243.166 274.275 256.307 267.499 259.901C259.234 264.264 250.148 250.199 237.879 253.792C227.099 256.975 227.613 269.551 218.167 271.194C207.747 272.991 202.357 257.539 190.653 259.798C185.725 260.773 180.232 264.777 177.203 269.449C174.996 272.837 175.612 274.12 173.456 276.943C168.323 283.719 158.723 284.335 157.851 284.387C145.993 285.003 137.625 275.198 134.699 271.759C125.921 261.441 124.997 249.634 124.741 244.809C124.33 236.133 125.973 227.612 131.722 213.649C145.993 179.153 162.984 166.371 157.748 155.437C156.67 153.127 154.001 149.739 146.403 147.635C155.695 174.995 142.04 198.968 128.18 202.048C114.936 204.974 93.8893 190.601 88.756 164.318C76.8466 168.938 72.6886 167.603 70.9946 165.807C66.1693 160.622 73.51 142.707 86.3433 133.313C89.7313 130.849 99.6386 123.559 108.006 126.896C120.891 131.927 121.455 158.466 123.509 158.209C125.921 157.901 117.554 122.122 131.722 112.061C137.369 107.954 145.12 108.981 151.383 110.777Z"
      fill="#E9DEFF"
    />
    <mask
      id="path-2-outside-1_3699_839"
      maskUnits="userSpaceOnUse"
      x="73.626"
      y="23.9287"
      width="235"
      height="257"
      fill="black"
    >
      <rect fill="white" x="73.626" y="23.9287" width="235" height="257" />
      <path
        d="M161.033 124.226C148.251 78.1284 161.803 39.1151 185.314 29.4644C197.377 24.5364 211.905 27.2571 222.12 33.6224C242.551 46.3017 251.431 76.7937 240.549 107.902C241.575 106.567 243.731 104.616 246.863 103.179C248.762 102.306 250.456 102.101 251.996 102.409C267.293 105.54 272.17 156.617 273.197 156.412C274.531 156.155 264.316 118.99 278.33 108.877C287.313 102.409 305.126 107.902 305.691 112.676C306.05 115.858 298.915 119.349 291.163 122.326C314.212 205.743 264.213 174.378 249.891 158.208C249.121 177.664 253.639 190.908 258.053 199.532C264.213 211.646 271.092 216.42 273.145 229.51C274.48 238.032 274.121 252.405 266.934 255.998C258.823 260.105 250.302 246.142 238.033 249.376C227.561 252.148 227.253 263.955 218.013 264.828C209.338 265.649 207.49 255.434 195.991 254.356C186.649 253.483 176.587 259.13 170.992 265.854C166.064 271.809 167.091 275.556 162.83 277.661C156.003 280.946 145.89 275.043 140.397 269.602C131.465 260.67 130.695 248.658 130.387 242.96C129.977 234.387 131.568 225.968 137.317 212.211C151.537 178.177 168.477 165.6 163.241 154.769C162.111 152.51 159.493 149.122 151.896 147.018C151.434 225.25 101.743 203.741 93.0165 156.206C63.0892 173.249 76.5385 144.092 97.7905 134.492C102.719 131.823 106.261 131.412 108.879 132.593C122.533 138.702 121.712 174.994 124.535 174.943C127.923 174.892 123.868 121.146 140.346 115.242C147.173 112.778 155.9 119.554 161.033 124.226Z"
      />
    </mask>
    <path
      d="M161.033 124.226C148.251 78.1284 161.803 39.1151 185.314 29.4644C197.377 24.5364 211.905 27.2571 222.12 33.6224C242.551 46.3017 251.431 76.7937 240.549 107.902C241.575 106.567 243.731 104.616 246.863 103.179C248.762 102.306 250.456 102.101 251.996 102.409C267.293 105.54 272.17 156.617 273.197 156.412C274.531 156.155 264.316 118.99 278.33 108.877C287.313 102.409 305.126 107.902 305.691 112.676C306.05 115.858 298.915 119.349 291.163 122.326C314.212 205.743 264.213 174.378 249.891 158.208C249.121 177.664 253.639 190.908 258.053 199.532C264.213 211.646 271.092 216.42 273.145 229.51C274.48 238.032 274.121 252.405 266.934 255.998C258.823 260.105 250.302 246.142 238.033 249.376C227.561 252.148 227.253 263.955 218.013 264.828C209.338 265.649 207.49 255.434 195.991 254.356C186.649 253.483 176.587 259.13 170.992 265.854C166.064 271.809 167.091 275.556 162.83 277.661C156.003 280.946 145.89 275.043 140.397 269.602C131.465 260.67 130.695 248.658 130.387 242.96C129.977 234.387 131.568 225.968 137.317 212.211C151.537 178.177 168.477 165.6 163.241 154.769C162.111 152.51 159.493 149.122 151.896 147.018C151.434 225.25 101.743 203.741 93.0165 156.206C63.0892 173.249 76.5385 144.092 97.7905 134.492C102.719 131.823 106.261 131.412 108.879 132.593C122.533 138.702 121.712 174.994 124.535 174.943C127.923 174.892 123.868 121.146 140.346 115.242C147.173 112.778 155.9 119.554 161.033 124.226Z"
      fill="white"
    />
    <path
      d="M161.033 124.226C148.251 78.1284 161.803 39.1151 185.314 29.4644C197.377 24.5364 211.905 27.2571 222.12 33.6224C242.551 46.3017 251.431 76.7937 240.549 107.902C241.575 106.567 243.731 104.616 246.863 103.179C248.762 102.306 250.456 102.101 251.996 102.409C267.293 105.54 272.17 156.617 273.197 156.412C274.531 156.155 264.316 118.99 278.33 108.877C287.313 102.409 305.126 107.902 305.691 112.676C306.05 115.858 298.915 119.349 291.163 122.326C314.212 205.743 264.213 174.378 249.891 158.208C249.121 177.664 253.639 190.908 258.053 199.532C264.213 211.646 271.092 216.42 273.145 229.51C274.48 238.032 274.121 252.405 266.934 255.998C258.823 260.105 250.302 246.142 238.033 249.376C227.561 252.148 227.253 263.955 218.013 264.828C209.338 265.649 207.49 255.434 195.991 254.356C186.649 253.483 176.587 259.13 170.992 265.854C166.064 271.809 167.091 275.556 162.83 277.661C156.003 280.946 145.89 275.043 140.397 269.602C131.465 260.67 130.695 248.658 130.387 242.96C129.977 234.387 131.568 225.968 137.317 212.211C151.537 178.177 168.477 165.6 163.241 154.769C162.111 152.51 159.493 149.122 151.896 147.018C151.434 225.25 101.743 203.741 93.0165 156.206C63.0892 173.249 76.5385 144.092 97.7905 134.492C102.719 131.823 106.261 131.412 108.879 132.593C122.533 138.702 121.712 174.994 124.535 174.943C127.923 174.892 123.868 121.146 140.346 115.242C147.173 112.778 155.9 119.554 161.033 124.226Z"
      stroke="#E9DEFF"
      stroke-width="4"
      mask="url(#path-2-outside-1_3699_839)"
    />
    <path
      d="M192.09 45.6358C189.215 44.6605 184.338 50.1532 182.234 55.3892C179.513 62.1652 180.334 71.3025 183.363 72.1752C187.059 73.3045 195.426 62.6785 194.862 52.6172C194.81 51.5392 194.502 46.4572 192.09 45.6358Z"
      fill="#1B1464"
    />
    <path
      d="M210.159 45.4812C212.571 44.3005 216.729 49.2799 218.629 54.3105C221.041 60.7785 220.579 69.9159 218.064 71.0965C214.984 72.5339 207.746 62.6779 208.003 52.6679C208.003 51.5385 208.157 46.5079 210.159 45.4812Z"
      fill="#1B1464"
    />
    <path
      d="M188.548 91.3743C189.318 91.2203 189.677 92.709 192.038 94.095C194.502 95.481 196.094 94.9676 196.402 95.789C197.12 97.5343 191.371 103.232 188.599 101.898C185.622 100.46 186.854 91.7336 188.548 91.3743Z"
      fill="#662D91"
      stroke="#662D91"
      stroke-width="0.557839"
      stroke-miterlimit="10"
    />
    <path
      d="M168.014 271.297C168.989 271.811 178.075 260.825 183.208 245.733C188.804 229.358 187.52 214.779 186.648 205.591C186.032 198.815 184.748 191.32 184.132 191.371C183.157 191.423 186.288 209.03 182.746 229.717C178.486 254.717 166.833 270.681 168.014 271.297Z"
      fill="#E9DEFF"
    />
    <path
      d="M251.123 251.893C246.041 247.838 241.985 242.345 237.981 232.13C231.873 216.627 230.179 201.227 229.768 190.345C230.743 194.759 232.181 200.868 234.234 208.106C235.312 211.956 237.879 220.785 240.394 227.202C243.269 234.389 248.915 245.425 260.927 257.54C258.463 256.565 254.87 254.871 251.123 251.893Z"
      fill="#E9DEFF"
    />
    <path
      d="M222.325 228.691C224.224 235.775 225.148 237.828 226.534 244.193C227.201 247.171 228.279 252.663 226.893 259.337C226.893 259.388 226.893 259.439 226.893 259.439C227.817 258.464 229.152 256.821 230.076 254.46C232.489 248.454 224.378 226.894 224.378 226.894C220.374 216.319 219.142 212.213 218.783 212.315C218.218 212.521 220.631 222.377 222.325 228.691Z"
      fill="#E9DEFF"
    />
    <path
      d="M145.017 339.98C144.35 345.935 171.556 355.483 195.837 357.947C231.668 361.592 270.989 350.709 270.784 342.547C270.527 332.332 208.003 327.301 162.984 333.82C150.818 335.566 145.274 337.414 145.017 339.98Z"
      fill="#A89EBC"
    />
  </svg>
</template>

<style scoped>
@media (max-width: 1200px) {
  .svg-illustration {
    width: 100%;
  }
}
</style>
