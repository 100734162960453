<template>
  <div>
    <div v-if="applicationNannyReady.value" class="content">
      <BaseH1 :text="'Moje ankiety'" :short="true" />

      <div class="my-applications content__my-applications">
        <ApplicationNannyListItem
          :item="applicationNannyReady.value"
          :is-wishlistable="false"
          :is-geo="false"
          :is-views-amount="true"
        />

        <div class="buttons my-application__buttons">
          <ButtonCta
            v-if="applicationNannyReady.value.meta.status === 'archived'"
            @click="runApplication(applicationNannyReady.value.id)"
            :label="'Aktywuj'"
            :size="'small'"
          />
          <ButtonTertiary
            v-if="applicationNannyReady.value.meta.status === 'published'"
            @click="updateApplication(applicationNannyReady.value.id)"
            :label="'Edytuj'"
            :size="'small'"
          />
          <ButtonLink
            v-if="applicationNannyReady.value.meta.status === 'published'"
            @click="archiveApplication(applicationNannyReady.value.id)"
            :label="'Ukryj'"
            class="buttons__link"
          />
        </div>
      </div>
    </div>

    <AccountApplicationsEmpty v-else />

    <NavMobileBack v-if="isMobile" />
  </div>
</template>

<script>
import useAccountApplications from "@/composables/useAccountApplications";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import ApplicationNannyListItem from "@/components/Application/Nanny/ApplicationNannyListItem.vue";
import ButtonTertiary from "@/components/UI/Base/ButtonTertiary.vue";
import NavMobileBack from "@/components/NavMobileBack.vue";
import useIsMobile from "@/composables/useIsMobile";
import AccountApplicationsEmpty from "@/views/Account/AccountApplicationsEmpty.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";

export default {
  components: {
    BaseH1,
    ApplicationNannyListItem,
    ButtonTertiary,
    NavMobileBack,
    AccountApplicationsEmpty,
    ButtonLink,
    ButtonCta,
  },

  setup() {
    const { isMobile } = useIsMobile();

    const {
      applicationNannyReady,
      updateApplication,
      pauseApplication,
      archiveApplication,
      runApplication,
    } = useAccountApplications();

    return {
      isMobile,
      applicationNannyReady,
      updateApplication,
      pauseApplication,
      archiveApplication,
      runApplication,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  padding: 60px 0;

  display: flex;
  flex-flow: column;
  row-gap: 60px;
}
.content__my-applications {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 30px;
}
.my-applications__my-application {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-end;
}
.my-application__buttons {
  margin-top: 30px;
  margin-right: 30px;

  width: fit-content;

  display: flex;
  flex-flow: row;
  column-gap: 30px;
}

@media (max-width: 1200px) {
  .content {
    padding: 30px 0;
    display: flex;
    flex-flow: column;
    row-gap: 30px;
  }
  .content__my-applications {
    display: flex;
    flex-flow: column;
    row-gap: 60px;
  }
  .my-applications__my-application {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
  .my-application__buttons {
    margin: 30px 0 0;

    width: 100%;

    display: flex;
    flex-flow: row;
    column-gap: 11px;
  }
  .my-application__buttons .buttons__link {
    flex: 1;
  }
}
</style>
