import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

import { onMounted } from "vue";
import { useRouter } from "vue-router";

export default function useAccountApplications() {
  const user = useUserStore();

  const { applicationNannyReady } = storeToRefs(user);

  const initAccountApplications = async () => {
    try {
      console.log("INIT MY_APPLICATIONS");
      await user.refetchUserStore();
    } catch (error) {
      console.log(error);
    }
  };

  onMounted(() => {
    if (user.storeMeta.isInitialized === true) {
      initAccountApplications();
    }
  });

  const router = useRouter();

  const createApplication = () => {
    router.push({ name: "ApplicationNannyCreate" });
  };

  const updateApplication = (id) => {
    router.push({ name: "ApplicationNannyUpdate", params: { id: id } });
  };

  // const refreshApplication = async (id) => {
  //   try {
  //     await user.refreshApplication({ id: id, applicationClass: "nanny" });
  //     console.log("APPLICATION REFRESHED");
  //   } catch (error) {
  //     console.log(error);
  //     alert(error.message);
  //   }
  // };

  const archiveApplication = async (id) => {
    try {
      await user.archiveApplication({ id: id, applicationClass: "nanny" });
      console.log("APPLICATION ARCHIEVED");
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const runApplication = async (id) => {
    try {
      await user.runApplication({ id: id, applicationClass: "nanny" });
      console.log("APPLICATION PUBLISHED");
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const pauseApplication = async (id) => {
    try {
      await user.pauseApplication({ id: id, applicationClass: "nanny" });
      console.log("APPLICATION PAUSED");
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return {
    applicationNannyReady,
    createApplication,
    updateApplication,
    pauseApplication,
    archiveApplication,
    runApplication,
    // refreshApplication,
  };
}
