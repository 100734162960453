<template>
  <div class="content--empty">
    <BaseH1 :text="'Tutaj tylko ja'" :short="true" />

    <SvgIllustrationsEmptySpace class="illustration" />

    <TextDescription
      :text="'A potem tu będzie Twója piękna ankieta,\nkiedy ją wypełnisz!'"
    />

    <ButtonCta
      @click="createApplication"
      :label="'Wypełnij ankietę'"
      :size="'large'"
      class="cta-button"
    />
  </div>
</template>

<script>
import useAccountApplications from "@/composables/useAccountApplications";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import useIsMobile from "@/composables/useIsMobile";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsEmptySpace from "@/components/Svg/Illustrations/SvgIllustrationsEmptySpace.vue";

export default {
  components: {
    BaseH1,
    ButtonCta,
    TextDescription,
    SvgIllustrationsEmptySpace,
  },

  setup() {
    const { isMobile } = useIsMobile();

    const { createApplication } = useAccountApplications();

    return {
      isMobile,
      createApplication,
    };
  },
};
</script>

<style scoped>
.content--empty {
  width: 100%;
  padding-top: 60px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 45px;
}
.content--empty .illustration {
  width: 269px;
  height: 269px;
}
.content--empty .cta-button {
  margin-top: 15px;
}

@media (max-width: 1200px) {
  .content--empty {
    padding: 10px 0 70px;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 15px;
  }
  .content--empty .illustration {
    width: 269px;
    height: 269px;
  }
  .content--empty .cta-button {
    margin-top: 15px;
  }
}
</style>
